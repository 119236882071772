import React from 'react'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ ourProcess }) => {
  if (!ourProcess || ourProcess.isHide) return null

  return (
    <div className={styles.ourProcess}>
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title}>{ourProcess.title}</h2>
          <Link className={styles.button} to={ourProcess.buttonUrl}>
            {ourProcess.buttonText}
          </Link>
        </div>

        <div className={styles.video}>
          <iframe
            src={`https://www.youtube.com/embed/${ourProcess.youtubeVideoId}`}
            frameBorder="0"
            allowfullscreen=""
          ></iframe>
        </div>
      </div>
    </div>
  )
}
