import React from 'react'
import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ press }) => {
  if (!press || press.isHide) return null

  return (
    <div className={styles.press}>
      <div className={styles.wrap}>
        <div className={styles.box}>
          <h2 className={styles.title}>{press.benefitsTitle}</h2>
          <div className={styles.benefits}>
            {press.benefitsItems &&
              press.benefitsItems.map(item => (
                <div className={styles.benefitsItem} key={item._key}>
                  <p className={styles.benefitsTitle}>{item.title}</p>
                  <p className={styles.benefitsText}>{prepareParagraph(item.text)}</p>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.box}>
          <img src={sanityImageUrl(press.image)} alt={press.image?.caption} className={styles.image} />
        </div>
        <div className={styles.box}>
          <h2 className={styles.title}>{press.pressTitle}</h2>
          <div className={styles.articles}>
            {press.pressItems &&
              press.pressItems.map(item => (
                <div className={styles.articlesItem} key={item._key}>
                  <img
                    {...srcSetProps(sanityImageUrl(item.image))}
                    className={styles.articlesImage}
                    alt={item.image?.caption}
                  />
                  <p className={styles.articlesText}>{item.text}</p>
                  <a href={item.buttonUrl} target="_blank" className={styles.articlesLink}>
                    {item.buttonText}
                  </a>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
