import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import { sortComponents } from '../utils/format'
import ProductSchema from '../components/Shared/Schema/Product'
import FaqSchema from '../components/Shared/Schema/Faq'
import Top from '../components/Pdp4Page/Top'
import Info from '../components/Pdp4Page/Info'
import Press from '../components/Pdp4Page/Press'
import Science from '../components/Pdp4Page/Science'
import BuildingBlocks from '../components/Pdp4Page/BuildingBlocks'
import Difference from '../components/Pdp4Page/Difference'
import HowItWorks from '../components/Pdp4Page/HowItWorks'
import OurProcess from '../components/Pdp4Page/OurProcess'
import Customers from '../components/Pdp4Page/Customers'
import Reviews from '../components/Pdp4Page/Reviews'
import Faq from '../components/Pdp4Page/Faq'
import Comment from '../components/Pdp4Page/Comment'
import References from '../components/Pdp4Page/References'
import Wim from '../components/Pdp4Page/Wim'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <Press press={data.press} />,
      priority: data.press?.priority || 99,
    },
    {
      render: <Science science={data.science} />,
      priority: data.science?.priority || 99,
    },
    {
      render: <BuildingBlocks buildingBlocks={data.buildingBlocks} />,
      priority: data.buildingBlocks?.priority || 99,
    },
    {
      render: <Difference difference={data.difference} />,
      priority: data.difference?.priority || 99,
    },
    {
      render: <HowItWorks howItWorks={data.howItWorks} />,
      priority: data.howItWorks?.priority || 99,
    },
    {
      render: <OurProcess ourProcess={data.ourProcess} />,
      priority: data.ourProcess?.priority || 99,
    },
    {
      render: <Customers customers={data.customers} />,
      priority: data.customers?.priority || 99,
    },
    {
      render: <Reviews reviews={data.reviews} />,
      priority: data.reviews?.priority || 99,
    },
    {
      render: <Faq faq={data.faq} />,
      priority: data.faq?.priority || 99,
    },
    {
      render: <Comment comment={data.comment} />,
      priority: data.comment?.priority || 99,
    },
    {
      render: <References references={data.references} />,
      priority: data.references?.priority || 99,
    },
    {
      render: <Wim wim={data.wim} />,
      priority: data.wim?.priority || 99,
    },
  ].sort(sortComponents)

  return (
    <Layout data={data} signUpUrl={data.info?.buttonUrl} signUpText={data.info?.buttonText}>
      <SEO {...data.seo} />
      <ProductSchema {...data.productSchema} />
      {(data?.faq?.faqList?.items || data?.faq?.items) && (
        <FaqSchema questions={data?.faq?.faqList?.items || data?.faq?.items} />
      )}
      <Top top={data.top} />
      <Info info={data.info} />
      {components.map(component => component.render)}
    </Layout>
  )
}
